import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/common/Layout'
import { Seo } from '../components/meta/Seo'
import SliceZone from '../components/slices/SliceZone'

export const query = graphql`
  {
    prismicHomepage {
      data {
        body {
          ... on PrismicHomepageDataBodyMainSlider {
            id
            slice_type
            slice_label
            items {
              desktop_image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              mobile_image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
        title
      }
    }
  }
`
const Homepage = (props) => {
  return (
    <Layout>
      <Seo title="Home" />
      <SliceZone slices={props.data.prismicHomepage.data.body} />
    </Layout>
  )
}
export default Homepage
